body {
  border: 1px solid black;
}

#App {
  display: flex;
  flex-direction: column;
  height: 99vh;
}

#Data_Selector_Section {
  display: flex;
  height: 35%;
  justify-content: space-around;
  align-items: center;
}

#Visualiser {
  display: flex;
  height: 75%;
  justify-content: space-around;
  align-items: center;
}